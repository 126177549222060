<template>

  <div>
    <h2>Καλώς ήρθατε στα Χρώματα της Καλοσύνης</h2>
    <div v-if="!bannerClosed">
      <p>Τα Χρώματα της Καλοσύνης είναι ένα καινοτόμο πρόγραμμα εκπαιδευτικής τεχνολογίας (ΕdTech) που βοηθά τα παιδιά να αναπτύξουν τις κοινωνικές και συναισθηματικές τους ικανότητες, αναπτύσσοντας δεξιότητες όπως η αυτογνωσία, η κοινωνική ευαισθητοποίηση, η αυτοδιαχείριση και η υπεύθυνη λήψη αποφάσεων. Έχει σχεδιαστεί για να βοηθήσει στην αντιμετώπιση των επιπτώσεων της πανδημίας και να διευκολύνει τη μετάβαση της διδασκαλίας πίσω στην τάξη μέσω μιας ολιστικής προσέγγισης. Το πρόγραμμα εμπεριέχει ηχητική διδασκαλία μέσα από ένα διαδραστικό ψηφιακό βιβλίο ασκήσεων, ενσωματώνοντας τα αξιολάτρευτα πολιτισμικά ευαίσθητα και άφυλλα γεωμετρικά μας πλάσματα για να βοηθήσουν τα παιδιά να αντιμετωπίσουν το τοξικό στρες και να βελτιώσουν τη συνολική τους ευεξία!</p>
      <p>Για να ξεκινήσετε, παρακαλούμε εγγραφείτε και δημιουργήστε το λογαριασμό σας στα Χρώματα της Καλοσύνης! Για να κατανοήσετε καλύτερα τον τρόπο με τον οποίο θα χρησιμοποιείτε το Kolibri και τις οδηγίες για τη δημιουργία λογαριασμού, μπορείτε να παρακολουθήσετε το εκπαιδευτικό βίντεο παρακάτω.</p>
      <p>Μόλις συνδεθείτε, δείτε τις Ενότητες Κατάρτισης Δασκάλων. Παρακαλείστε να τις παρακολουθήσετε πριν ξεκινήσετε το μάθημα. Ξεκινήστε με την ενότητα <b>Εισαγωγή</b>, η οποία θα σας τα εξηγήσει όλα!</p>
      <p>Για να μάθετε περισσότερα για το πρόγραμμα, επισκεφθείτε την ιστοσελίδα <a href="www.amalalliance.org">www.amalalliance.org</a> ή <a href="www.colorsofkindness.org">www.colorsofkindness.org</a>.</p>
      <KContentRenderer
        v-if="content"
        :kind="content.kind"
        :lang="content.lang"
        :files="content.files"
        :options="content.options"
        :available="content.available"
        :duration="content.duration"
      />
    </div>
  </div>

</template>


<script>
  import plugin_data from 'plugin_data';

  export default {
    name: 'AmalAllianceBannerContent',
    props: {
      bannerClosed: {
        required: true,
        type: Boolean,
        default: false,
      },
    },
    computed: {
      content() {
        return plugin_data.banner_video
      }
    }
  };
</script>


<style lang="scss" scoped></style>
