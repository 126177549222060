import coreBannerContent from 'kolibri.utils.coreBannerContent';
import AmalAllianceBannerContent from './AmalAllianceBannerContent';
import KolibriModule from 'kolibri_module';

class AmalAllianceBannerModule extends KolibriModule {
  ready() {
    coreBannerContent.register(AmalAllianceBannerContent);
  }
}

export default new AmalAllianceBannerModule();
